import axios from "axios";
import firebase from "firebase";

export default class Users {
  remoteUrl = "https://us-central1-food-delights.cloudfunctions.net/";

  // Fetch an id token from Firebase for API authentication
  async getToken() {
    const user = firebase.auth().currentUser;
    const response = await user.getIdToken(false);
    return response;
  }

  // Perform on login actions using cloud function
  async onLogin() {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "onLogin",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Provision a user account using cloud function
  async provision(user) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "provisionAccount",
      data: user,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Create a checkout session using cloud function
  async createCheckoutSession(prices) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "createCheckoutSession",
      data: prices,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Get list of users from cloud function
  async getUsers() {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "get",
      url: this.remoteUrl + "getUsers",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Get list of users from cloud function
  async getUser(uid) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "get",
      url: this.remoteUrl + "getUser/" + uid,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Add a user using cloud function
  async addUser(user) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "post",
      url: this.remoteUrl + "addUser",
      data: user,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Update a user using cloud function
  async updateUser(uid, user) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "put",
      url: this.remoteUrl + "updateUser/" + uid,
      data: user,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Delete a user using cloud function
  async deleteUser(uid) {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "delete",
      url: this.remoteUrl + "deleteUser/" + uid,
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }

  // Persist updated role permissions to all users using cloud function
  async persistRolePermissions() {
    // Get token for authentication
    const token = await this.getToken();
    // Make API call to remote API
    return axios({
      method: "put",
      url: this.remoteUrl + "/persistRolePermissions",
      headers: {
        Authorization: "Bearer " + token,
        Environment: window.environment
      }
    });
  }
}
