<template>
  <div class="help-desk-card container-fluid card card-shadow p-0">
    <b-row>
      <b-col>
        <div class="tab-bar-wrapper">
          <transition name="fade">
            <button
              class="scroller scroller-left float-left mt-2 text-secondary"
              v-if="showScrollLeft"
              @click="scrollLeft"
            >
              <i class="fa fa-chevron-left"></i>
            </button>
          </transition>
          <transition name="fade">
            <button
              class="scroller scroller-right float-right mt-2 text-secondary"
              v-if="showScrollRight"
              @click="scrollRight"
            >
              <i class="fa fa-chevron-right"></i>
            </button>
          </transition>
          <div class="tab-bar-inner-wrapper">
            <nav
              class="nav nav-tabs help-desk-nav-tab-bar list"
              :style="{ transform: 'translate(' + scrollOffset + 'px, 0)' }"
              id="helpDeskTabs"
              role="tablist"
            >
              <a
                :class="
                  'nav-item nav-link help-desk-nav-tabs text-secondary' +
                    (activeTab === index ? ' active bg-light' : '')
                "
                role="tab"
                v-for="(tab, index) in openTabs"
                :key="index"
                @click="showTab(index)"
              >
                {{ tab.name }}
                <b-button
                  size="sm"
                  class="help-desk-tab-close"
                  @click.stop="closeTab(index)"
                  v-if="tab.name !== name"
                >
                  <i class="fas fa-window-close"></i>
                </b-button>
              </a>
            </nav>
          </div>
        </div>
      </b-col>
      <b-col class="search-box-col d-flex justify-content-end">
        <div class="search-box-wrapper">
          <b-form inline>
            <b-form-input
              size="sm"
              v-model="searchText"
              placeholder="Search..."
              class="search-box"
            ></b-form-input>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <b-row class="tab-content-row mx-0">
      <b-col class="px-0 full-height">
        <div id="tab-content" class="tab-content full-height overflow-hidden">
          <!-- Inbox Tab -->
          <div
            class="overflow-auto full-height"
            role="tabpanel"
            v-if="activeTab === 0"
          >
            <div>
              <nav
                class="navbar navbar-expand-md navbar-light bg-light pt-2 pb-2"
              >
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <!-- Select all -->
                    <b-button
                      variant="outline-secondary"
                      size="sm"
                      @click="toggleSelected"
                    >
                      <i class="far fa-check-square"></i>
                    </b-button>
                  </li>
                  <li class="nav-item">
                    <!-- Move dropdown -->
                    <b-dropdown
                      class="ml-2"
                      variant="outline-secondary"
                      size="sm"
                      v-if="name !== 'Inbox'"
                    >
                      <template #button-content>
                        <i class="fas fa-folder-open"></i>
                        Move&nbsp;
                      </template>
                      <b-dropdown-item-button
                        v-for="(box, index) in moveMailboxes"
                        :key="index"
                        @click="moveTickets(box.name)"
                        >{{ box.name }}</b-dropdown-item-button
                      >
                    </b-dropdown>
                  </li>
                  <li class="nav-item">
                    <!-- Archive dropdown -->
                    <b-dropdown
                      split
                      class="ml-2"
                      variant="outline-secondary"
                      size="sm"
                      @click="archiveTickets"
                    >
                      <template #button-content>
                        <i class="fas fa-archive"></i> Archive
                      </template>
                      <b-dropdown-item-button @click="markTicketsAsSpam"
                        ><i class="fas fa-exclamation-circle"></i> Mark as
                        Spam</b-dropdown-item-button
                      >
                      <b-dropdown-item-button v-b-modal.confirmBulkDelete
                        ><i class="fas fa-trash-alt"></i>
                        {{
                          name === "Trash" ? "Delete" : "Move to Trash"
                        }}</b-dropdown-item-button
                      >
                    </b-dropdown>
                  </li>
                  <li class="nav-item">
                    <!-- Assign dropdown -->
                    <b-dropdown
                      class="ml-2"
                      variant="outline-secondary"
                      size="sm"
                    >
                      <template #button-content>
                        <i class="fas fa-user-friends"></i>
                        Assign&nbsp;
                      </template>
                      <b-dropdown-item-button
                        v-for="(user, index) in support_users"
                        :key="index"
                        @click="assignTickets(user.uid, user.name, user.avatar)"
                        >{{ user.name }}</b-dropdown-item-button
                      >
                    </b-dropdown>
                  </li>
                  <li class="nav-item">
                    <!-- Merge -->
                    <b-button
                      variant="outline-secondary"
                      size="sm"
                      class="ml-2"
                      v-b-modal.confirmMerge
                    >
                      <i class="fas fa-object-group"></i>
                      Merge&nbsp;
                    </b-button>
                  </li>
                </ul>
                <ul class="navbar-nav justify-content-end">
                  <li class="nav-item">
                    <!-- Submit ticket -->
                    <b-button
                      variant="outline-success"
                      size="sm"
                      class="ml-2 float-right"
                      @click="
                        navigate(
                          mailboxKey === null
                            ? 'submit-ticket-inbox'
                            : 'submit-ticket-shared',
                          { id: mailboxKey, slug: mailboxSlug }
                        )
                      "
                    >
                      <i class="far fa-envelope"></i> Submit Ticket
                    </b-button>
                  </li>
                </ul>

                <b-modal
                  id="confirmMerge"
                  title="Merge Ticket(s)"
                  ok-title="Merge"
                  ok-variant="warning"
                  :ok-disabled="!mergeKey"
                  @ok="mergeTickets()"
                  @show="mergeKey = null"
                >
                  Please confirm the ticket that the selected ticket(s) will be
                  merged into:

                  <b-form-radio-group
                    v-model="mergeKey"
                    :options="mergeTicketList"
                    text-field="id"
                    value-field="key"
                    class="ml-5 mt-3"
                    stacked
                  ></b-form-radio-group>
                </b-modal>

                <b-modal
                  id="confirmBulkDelete"
                  title="Delete Ticket(s)"
                  ok-title="Delete"
                  ok-variant="danger"
                  @ok="deleteTickets()"
                >
                  Are you sure you want to delete the selected ticket(s)? Ticket
                  history should be preserved unless absolutely necessary.
                </b-modal>
              </nav>
              <b-table
                hover
                responsive
                striped
                selectable
                :items="filteredTickets"
                :fields="mailboxFields"
              >
                <template #cell(select)="data">
                  <b-form-checkbox
                    v-model="selectedTickets"
                    :value="data.item['.key']"
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(status)="data">
                  <b-form-select
                    :options="statuses"
                    value-field="status"
                    text-field="status"
                    v-model="data.item.status"
                    @change="updateStatus(data.item['.key'], $event)"
                    size="sm"
                  ></b-form-select>
                </template>
                <template #cell(assigned)="data">
                  <b-form-select
                    :options="support_users"
                    value-field="uid"
                    text-field="name"
                    :value="data.item.assigned.uid"
                    @change="assignTicket(data.item['.key'], $event)"
                    size="sm"
                  ></b-form-select>
                </template>
                <template #cell(date)="data">
                  {{ formatDate(data.item.date) }}
                </template>
                <template #cell(updated)="data">
                  {{ formatDate(getLastUpdateDate(data.item.updates)) }}
                </template>
                <template #cell(sla)="data">
                  <b-progress
                    class="sla-progress"
                    :variant="data.item.sla.style"
                    :value="data.item.sla.percent"
                  ></b-progress>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    variant="outline-primary"
                    size="sm"
                    @click="viewTicket(data.item)"
                    ><i class="far fa-file-alt"></i> View</b-button
                  >
                </template>
              </b-table>
            </div>
          </div>

          <!-- Ticket Tab(s) -->
          <div class="full-height" role="tabpanel" v-if="activeTab > 0">
            <nav
              class="navbar navbar-expand-md navbar-light bg-light pt-2 pb-2"
            >
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <form class="form-inline">
                    <!-- Assign dropdown -->
                    <label class="ml-1">Assigned: </label>
                    <b-form-select
                      :options="support_users"
                      value-field="uid"
                      text-field="name"
                      :value="ticket.assigned.uid"
                      @change="assignTicket(ticketKey, $event)"
                      size="sm"
                      class="ml-2"
                    ></b-form-select>
                    <!-- Status dropdown -->
                    <label class="ml-3">Status: </label>
                    <b-form-select
                      :options="statuses"
                      value-field="status"
                      text-field="status"
                      v-model="ticket.status"
                      @change="updateStatus(ticketKey, $event)"
                      size="sm"
                      class="ml-2"
                    ></b-form-select>
                  </form>
                </li>
              </ul>
              <ul class="navbar-nav justify-content-end">
                <li class="nav-item"></li>
              </ul>
            </nav>

            <div class="container-fluid" style="height: calc(100% - 47px);">
              <div class="row full-height">
                <!-- Ticket Updates -->
                <div
                  class="col-8 full-height bg-secondary"
                  style="width: calc(100% - 300px); max-width: calc(100% - 300px); flex-basis: calc(100% - 300px);"
                >
                  <div class="container-fluid full-height">
                    <!-- Update History -->
                    <div class="row" style="height: calc(100% - 200px);">
                      <div class="col full-height" style="overflow-y: auto;">
                        <div v-for="(update, index) in history" :key="index">
                          <div
                            :class="
                              'card ticket-card ticket-update container ' +
                                update.style
                            "
                            style="width: calc(100% - 50px);"
                          >
                            <div class="row">
                              <div
                                class="col-2"
                                style="width: 70px; flex-basis: 70px;"
                                v-if="update.message || update.text"
                              >
                                <div class="chip-lg">
                                  <img
                                    :src="getUpdateAvatar(update)"
                                    alt="Person"
                                    width="96"
                                    height="96"
                                  />
                                </div>
                              </div>
                              <div
                                :class="'col' + (update.message ? '-10' : '')"
                                :style="
                                  update.message
                                    ? 'width: calc(100% - 70px); max-width: calc(100% - 70px); flex-basis: calc(100% - 70px);'
                                    : ''
                                "
                              >
                                <h6
                                  :class="
                                    update.updateType === 'activity'
                                      ? 'text-white'
                                      : 'text-secondary'
                                  "
                                >
                                  {{
                                    update.updateType === "activity"
                                      ? "Activity by "
                                      : ""
                                  }}
                                  {{ update.author.name
                                  }}{{
                                    update.updateType === "activity"
                                      ? ": " + update.event
                                      : ""
                                  }}
                                  <small
                                    v-if="update.updateType === 'update'"
                                    >{{ deriveHeader(update) }}</small
                                  >
                                  <small v-if="update.updateType === 'note'"
                                    >Internal note</small
                                  >
                                  <button
                                    class="btn btn-sm btn-outline-danger float-right ml-1 btn-borderless"
                                    v-if="update.updateType !== 'activity'"
                                    v-b-modal="
                                      'confirmUpdateDelete' + update.key
                                    "
                                  >
                                    <i class="far fa-trash-alt"></i>
                                  </button>
                                  <button
                                    class="btn btn-sm btn-outline-primary float-right ml-1 btn-borderless"
                                    v-if="
                                      (update.updateType === 'update' &&
                                        update.type === 'update') ||
                                        update.updateType === 'note'
                                    "
                                    @click="editUpdate(update)"
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                  <span class="time float-right"
                                    ><small>{{
                                      formatDate(update.date)
                                    }}</small></span
                                  >
                                </h6>
                                <p
                                  v-if="update.message"
                                  v-html="update.message"
                                ></p>
                                <p v-if="update.text" v-html="update.text"></p>
                              </div>
                            </div>
                          </div>

                          <b-modal
                            :id="'confirmUpdateDelete' + update.key"
                            :title="'Delete ' + update.updateType"
                            ok-title="Delete"
                            ok-variant="danger"
                            @ok="deleteUpdate(update.key, update.updateType)"
                          >
                            Are you sure you want to delete this
                            {{ update.updateType }}? This cannot be undone.
                          </b-modal>
                        </div>
                      </div>
                    </div>
                    <!-- Text Editor -->
                    <div class="row" style="height: 200px;">
                      <div class="col full-height pr-0">
                        <div id="ticket-new-update" class="card comment-box">
                          <!-- <textarea
                            class="ticket-update-editor"
                            placeholder="Add an update..."
                            data-internal="N"
                          ></textarea> -->
                          <div class="position-relative editor-buttons">
                            <b-dropdown
                              size="sm"
                              class="mr-1"
                              variant="outline-secondary"
                            >
                              <template #button-content>
                                <i class="fas fa-reply"></i> Replies
                              </template>
                              <b-dropdown-item
                                v-for="(reply, index) in replies"
                                :key="index"
                                @click="insertReply(reply)"
                                >{{ reply.name }}</b-dropdown-item
                              >
                            </b-dropdown>
                            <b-button
                              size="sm"
                              class="mr-1"
                              variant="outline-warning"
                              @click="toggleNote"
                              :disabled="update.existing"
                              ><i class="far fa-sticky-note"></i> Note</b-button
                            >
                            <b-button
                              size="sm"
                              class="mr-1"
                              variant="outline-primary"
                              @click="postUpdate"
                            >
                              <span v-if="posting">
                                <b-spinner small></b-spinner>
                                <span class="sr-only">Posting...</span>
                              </span>
                              <span v-else>
                                <i class="fas fa-paper-plane"></i> Post
                              </span>
                            </b-button>
                            <b-button
                              size="sm"
                              variant="outline-danger"
                              @click="resetUpdate"
                              ><i class="fas fa-eraser"></i>
                            </b-button>
                          </div>
                          <ckeditor
                            :editor="editor"
                            v-model="update.text"
                            :config="editorConfig"
                            class="ticket-update-editor"
                            @ready="addEditorButtons"
                          ></ckeditor>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- User Sidebar -->
                <div
                  class="col-4 container"
                  style="width: 300px; flex-basis: 300px;"
                >
                  <div class="row">
                    <div class="col text-center mt-5">
                      <div class="chip-xl">
                        <img
                          :src="sender.avatar"
                          alt="Person"
                          width="96"
                          height="96"
                        />
                      </div>
                      <h4>{{ sender.name }}</h4>
                      <small class="text-muted">{{ sender.type }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col sender-details">
                      <ul>
                        <li
                          class="d-flex justify-content-between align-items-center"
                        >
                          <small class="text-muted"
                            ><i class="far fa-clock"></i></small
                          ><small class="text-muted">{{
                            sender.signup_date
                          }}</small>
                        </li>
                        <li
                          class="d-flex justify-content-between align-items-center"
                        >
                          <small class="text-muted"
                            ><i class="far fa-envelope"></i></small
                          ><small class="text-muted">{{ sender.email }}</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col sender-details">
                      <h6 class="text-secondary">SLA</h6>
                      <span
                        v-for="(sla, index) in computeSLAs(ticket, sender)"
                        :key="index"
                      >
                        <small class="text-muted mt-3">{{ sla.name }}</small>
                        <div
                          class="progress"
                          style="height: 25px; width: 100%;"
                        >
                          <div
                            :class="'progress-bar bg-' + sla.style"
                            role="progressbar"
                            :style="'width: ' + sla.percent + '%;'"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="sla-text text-muted">
                          <i class="fas fa-stopwatch"></i> {{ sla.due }}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import { db } from "../firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Users from "@/firebase/users";
import { DateTime } from "luxon";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "Mailbox",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    name: {
      type: String,
      required: true,
      default: "Inbox"
    },
    mailboxKey: {
      type: String,
      required: false,
      default: null
    },
    mailboxSlug: {
      type: String,
      required: false,
      default: null
    },
    tickets: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      bind: [
        { ref: "helpdesk/mailboxes", target: "mailboxes" },
        { ref: "helpdesk/meta/statuses", target: "statuses" },
        { ref: "helpdesk/meta/replies", target: "replies" },
        { ref: "helpdesk/meta/slas", target: "slas" }
      ],
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "|",
          "indent",
          "outdent",
          "|",
          "imageUpload",
          "blockQuote",
          "insertTable",
          "undo",
          "redo"
        ],
        removePlugins: ["Heading", "MediaEmbed"]
      },
      update: {
        text: "",
        type: "update",
        existing: false,
        key: null
      },
      posting: false,
      mailboxes: [],
      tabs: [],
      activeTab: 0,
      showScrollLeft: false,
      showScrollRight: false,
      scrollDistance: 200,
      scrollOffset: 0,
      searchText: "",
      mailboxFields: [
        {
          key: "select",
          label: "",
          sortable: false
        },
        {
          key: "id",
          label: "#",
          sortable: true
        },
        {
          key: "sender.name",
          label: "Sender",
          sortable: true
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "assigned",
          label: "Assigned",
          sortable: true
        },
        {
          key: "date",
          label: "Created",
          sortable: true
        },
        {
          key: "updated",
          label: "Last Updated",
          sortable: true
        },
        {
          key: "sla",
          label: "SLA",
          sortable: true
        },
        {
          key: "actions",
          label: "",
          sortable: true
        }
      ],
      statuses: [],
      rule_actions: [],
      slas: [],
      support_users: [],
      support_avatars: {},
      roles: [],
      replies: [],
      selectedTickets: [],
      ticket: {},
      ticketKey: null,
      sender: {},
      avatar: require("../assets/img_avatar.png"),
      mergeKey: null
    };
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    openTabs() {
      let tabs = [{ name: this.name }];
      tabs.push(...this.tabs);
      return tabs;
    },
    history() {
      let history = [];
      let updates = "updates" in this.ticket ? this.ticket.updates : {};
      let activities = "activity" in this.ticket ? this.ticket.activity : {};
      let notes = "notes" in this.ticket ? this.ticket.notes : {};
      Object.keys(updates).forEach(key => {
        const update = this.ticket.updates[key];
        history.push({
          ...update,
          updateType: "update",
          style: update.type === "reply" ? "responder" : "sender",
          key: key
        });
      });
      Object.keys(activities).forEach(key => {
        const activity = this.ticket.activity[key];
        history.push({
          ...activity,
          updateType: "activity",
          style: "activity",
          key: key
        });
      });
      Object.keys(notes).forEach(key => {
        const note = this.ticket.notes[key];
        history.push({
          ...note,
          updateType: "note",
          style: "responder-internal",
          key: key
        });
      });
      console.log(history);
      history.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        } else if (b.date < a.date) {
          return 1;
        } else {
          return 0;
        }
      });
      return history;
    },
    moveMailboxes() {
      return this.mailboxes
        .filter(mailbox => {
          if (
            mailbox.name !== "Archive" &&
            mailbox.name !== "Trash" &&
            mailbox.name !== "Spam"
          ) {
            return true;
          } else {
            return false;
          }
        })
        .sort((a, b) => {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          } else if (nameB < nameA) {
            return 1;
          } else {
            return 0;
          }
        });
    },
    mergeTicketList() {
      return this.selectedTickets.map(key => {
        let ticket = this.tickets[this.getIndex(this.tickets, key)];
        return {
          key: key,
          id: ticket.id,
          subject: ticket.subject
        };
      });
    },
    filteredTickets() {
      if (this.searchText.length > 0) {
        const searchText = this.searchText.toLowerCase();
        return this.tickets.filter(ticket => {
          let match = false;
          if (
            ticket[".key"].toLowerCase().includes(searchText) ||
            ticket.id.toLowerCase().includes(searchText) ||
            ticket.subject.toLowerCase().includes(searchText)
          ) {
            match = true;
          }
          if ("notes" in ticket) {
            Object.values(ticket.notes).forEach(note => {
              if (note.text.toLowerCase().includes(searchText)) {
                match = true;
              }
            });
          }
          if ("updates" in ticket) {
            Object.values(ticket.updates).forEach(update => {
              if (
                update.subject.toLowerCase().includes(searchText) ||
                update.message.toLowerCase().includes(searchText)
              ) {
                match = true;
              }
            });
          }
          return match;
        });
      } else {
        return this.tickets;
      }
    }
  },
  watch: {
    tickets: {
      immediate: true,
      handler(tickets) {
        tickets.forEach((ticket, i) => {
          if (!("assigned" in ticket)) {
            this.tickets[i].assigned = {};
          }
          this.tickets[i].sla = this.getNextDueSLA(ticket, ticket.sender);
        });
      }
    },
    slas: {
      immediate: true,
      handler() {
        this.tickets.forEach((ticket, i) => {
          this.tickets[i].sla = this.getNextDueSLA(ticket, ticket.sender);
        });
      }
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"]),
    formatDate(timestamp) {
      if (!isNaN(timestamp)) {
        const dt = new DateTime.fromMillis(timestamp);
        return dt.toLocaleString(DateTime.DATETIME_MED);
      } else {
        return "";
      }
    },
    getSupportUsers() {
      this.loading = true;
      const u = new Users();
      u.getUsers()
        .then(resp => {
          let users = resp.data.users;
          this.support_users = users
            .filter(user => {
              try {
                if (
                  user.db.permissions &&
                  user.db.permissions["tickets-edit"]
                ) {
                  return true;
                } else {
                  return false;
                }
              } catch (e) {
                return false;
              }
            })
            .map(user => {
              let u = new Object();
              u.uid = user.auth.uid;
              u.name = user.auth.displayName;
              u.email = user.auth.email;
              if ("profile" in user.db && "avatar" in user.db.profile) {
                u.avatar = user.db.profile.avatar;
              }
              return u;
            });
          this.loading = false;
          // Get avatars for support users
          this.support_users.forEach((user, index) => {
            if ("avatar" in user) {
              this.getImageUrl("avatars/" + user.avatar).then(url => {
                this.support_users[index].avatar = url;
                this.support_avatars[user.uid] = url;
              });
            } else {
              this.support_users[index].avatar = this.avatar;
            }
          });
        })
        .catch(error => {
          console.error("Unable to fetch users", error);
          this.loading = false;
        });
    },
    toggleSelected() {
      if (this.selectedTickets.length === 0) {
        this.selectedTickets = this.tickets.map(ticket => {
          return ticket[".key"];
        });
      } else {
        this.selectedTickets = [];
      }
    },
    tabListPosition() {
      return this.scrollOffset;
    },
    // Get width of tab list
    widthOfList() {
      let itemsWidth = 0;
      document.querySelectorAll(".list a").forEach(el => {
        itemsWidth += el.offsetWidth;
      });
      return itemsWidth;
    },
    // Get width of tab list hidden on left side
    getHiddenLeft() {
      //let pos = $(".list").position().left;
      return this.tabListPosition() > 0
        ? 0
        : Math.floor(Math.abs(this.tabListPosition()));
    },
    // Get width of tab list hidden on right side
    getHiddenRight() {
      if (document.querySelector(".tab-bar-inner-wrapper") !== null) {
        let pos =
          this.widthOfList() -
          document.querySelector(".tab-bar-inner-wrapper").offsetWidth +
          this.tabListPosition();
        return pos < 0 ? 0 : Math.floor(pos);
      } else {
        return 0;
      }
    },
    // Get current viewport of list tabs
    getListViewport() {
      let vw = new Object();
      vw.left = this.getHiddenLeft();
      vw.right = this.widthOfList() - this.getHiddenRight();
      return vw;
    },
    scrollToTab(tab) {
      var controller = this;
      let vw = this.getListViewport;
      // eslint-disable-next-line no-undef
      let pos_left = $(tab).position().left;
      // eslint-disable-next-line no-undef
      let pos_right = pos_left + $(tab).outerWidth();
      // Check if either position exists outside of viewport
      if (
        !(pos_left >= vw.left && pos_left <= vw.right) ||
        !(pos_right >= vw.left && pos_right <= vw.right)
      ) {
        let distance_left = Math.abs(vw.left - pos_left);
        let distance_right = Math.abs(vw.right - pos_left);
        // Check which way to shift
        if (distance_left <= distance_right) {
          let distance = Math.abs(pos_left - vw.left - 20);
          // eslint-disable-next-line no-undef
          $(".list").animate(
            { left: "+=" + distance + "px" },
            "slow",
            function() {
              controller.reAdjust();
            }
          );
        } else {
          let distance = Math.abs(pos_right - vw.right + 20);
          // eslint-disable-next-line no-undef
          $(".list").animate(
            { left: "-=" + distance + "px" },
            "slow",
            function() {
              controller.reAdjust();
            }
          );
        }
      }
    },
    // Readjust tab list scroll
    reAdjust() {
      console.log("Left", this.getHiddenLeft(), "Right", this.getHiddenRight());
      if (this.getHiddenLeft() === 0) {
        this.showScrollLeft = false;
      } else {
        this.showScrollLeft = true;
      }
      if (this.getHiddenRight() === 0) {
        this.showScrollRight = false;
      } else {
        this.showScrollRight = true;
      }
    },
    scrollLeft() {
      this.showScrollRight = true;

      let pos = this.getHiddenLeft();
      let distance = this.scrollDistance > pos ? pos : this.scrollDistance;
      console.log("Distance", distance);
      this.scrollOffset += distance;
      this.reAdjust();
      // $(".list").animate({ left: "+=" + distance + "px" }, "slow", () => {
      //   this.reAdjust();
      // });
    },
    scrollRight() {
      this.showScrollLeft = true;

      let pos = this.getHiddenRight();
      let distance = this.scrollDistance > pos ? pos : this.scrollDistance;
      console.log("Distance", distance);
      this.scrollOffset -= distance;
      this.reAdjust();
      // $(".list").animate({ left: "-=" + distance + "px" }, "slow", () => {
      //   this.reAdjust();
      // });
    },
    moveTickets(mailboxName) {
      if (mailboxName && this.selectedTickets.length > 0) {
        this.selectedTickets.forEach(key => {
          if (key) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Delete ticket from mailboxes
            const deletes = [];
            if ("mailbox" in ticket) {
              deletes.push(
                this.deleteObject("helpdesk/tickets/" + ticket.mailbox, key)
              );
            }
            Promise.all(deletes)
              .then(() => {
                // Insert spam ticket into spam
                db.ref(this.env + "helpdesk/mailboxes")
                  .orderByChild("name")
                  .equalTo(mailboxName)
                  .once("value")
                  .then(snapshot => {
                    const mailbox = snapshot.val();
                    const mailboxKey = Object.keys(mailbox)[0];
                    this.updateObject("helpdesk/tickets/" + mailboxKey, key, {
                      ...ticket,
                      mailbox: mailboxKey
                    });
                  });
              })
              .catch(err => {
                console.error(
                  "Failed to move ticket(s)",
                  this.selectedTickets.toString(),
                  err
                );
                window.toastr.error(
                  "An error occurred moving ticket(s): " + err
                );
              });
          }
        });
        this.selectedTickets = [];
      }
    },
    archiveTickets() {
      if (this.selectedTickets.length > 0) {
        this.selectedTickets.forEach(key => {
          if (key) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Delete ticket from mailboxes
            const deletes = [];
            if ("sender" in ticket) {
              deletes.push(
                this.deleteObject("tickets/" + ticket.sender.uid, key)
              );
            }
            if ("assigned" in ticket) {
              deletes.push(
                this.deleteObject(
                  "helpdesk/inboxes/" + ticket.assigned.uid,
                  key
                )
              );
            }
            if ("mailbox" in ticket) {
              deletes.push(
                this.deleteObject("helpdesk/tickets/" + ticket.mailbox, key)
              );
            }
            Promise.all(deletes)
              .then(() => {
                // Insert spam ticket into spam
                db.ref(this.env + "helpdesk/mailboxes")
                  .orderByChild("name")
                  .equalTo("Archive")
                  .once("value")
                  .then(snapshot => {
                    const mailbox = snapshot.val();
                    const archiveKey = Object.keys(mailbox)[0];
                    this.updateObject("helpdesk/tickets/" + archiveKey, key, {
                      ...ticket,
                      spam: true
                    });
                  });
              })
              .catch(err => {
                console.error(
                  "Failed to archive ticket(s)",
                  this.selectedTickets.toString(),
                  err
                );
                window.toastr.error(
                  "An error occurred archiving ticket(s): " + err
                );
              });
          }
        });
        this.selectedTickets = [];
      }
    },
    markTicketsAsSpam() {
      if (this.selectedTickets.length > 0) {
        this.selectedTickets.forEach(key => {
          if (key) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Delete ticket from mailboxes
            const deletes = [];
            if ("sender" in ticket) {
              deletes.push(
                this.deleteObject("tickets/" + ticket.sender.uid, key)
              );
            }
            if ("assigned" in ticket) {
              deletes.push(
                this.deleteObject(
                  "helpdesk/inboxes/" + ticket.assigned.uid,
                  key
                )
              );
            }
            if ("mailbox" in ticket) {
              deletes.push(
                this.deleteObject("helpdesk/tickets/" + ticket.mailbox, key)
              );
            }
            Promise.all(deletes)
              .then(() => {
                // Insert spam ticket into spam
                db.ref(this.env + "helpdesk/mailboxes")
                  .orderByChild("name")
                  .equalTo("Spam")
                  .once("value")
                  .then(snapshot => {
                    const mailbox = snapshot.val();
                    const spamKey = Object.keys(mailbox)[0];
                    this.updateObject("helpdesk/tickets/" + spamKey, key, {
                      ...ticket,
                      spam: true
                    });
                  });
              })
              .catch(err => {
                console.error(
                  "Failed to mark tickets as spam",
                  this.selectedTickets.toString(),
                  err
                );
                window.toastr.error(
                  "An error occurred marking ticket(s) as spam: " + err
                );
              });
          }
        });
        this.selectedTickets = [];
      }
    },
    deleteTickets() {
      if (this.selectedTickets.length > 0) {
        this.selectedTickets.forEach(key => {
          if (key) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Delete ticket from mailboxes
            const deletes = [];
            if ("sender" in ticket) {
              deletes.push(
                this.deleteObject("tickets/" + ticket.sender.uid, key)
              );
            }
            if ("assigned" in ticket) {
              deletes.push(
                this.deleteObject(
                  "helpdesk/inboxes/" + ticket.assigned.uid,
                  key
                )
              );
            }
            if ("mailbox" in ticket) {
              deletes.push(
                this.deleteObject("helpdesk/tickets/" + ticket.mailbox, key)
              );
            }
            Promise.all(deletes)
              .then(() => {
                // Insert deleted ticket into trash
                db.ref(this.env + "helpdesk/mailboxes")
                  .orderByChild("name")
                  .equalTo("Trash")
                  .once("value")
                  .then(snapshot => {
                    const mailbox = snapshot.val();
                    const trashKey = Object.keys(mailbox)[0];
                    this.updateObject(
                      "helpdesk/tickets/" + trashKey,
                      key,
                      ticket
                    );
                  });
              })
              .catch(err => {
                console.error(
                  "Failed to delete tickets",
                  this.selectedTickets.toString(),
                  err
                );
                window.toastr.error(
                  "An error occurred deleting the ticket(s): " + err
                );
              });
          }
        });
        this.selectedTickets = [];
      }
    },
    assignTickets(uid, name, avatar = null) {
      if (this.selectedTickets.length > 0) {
        this.selectedTickets.forEach(key => {
          if (key) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Delete ticket from inbox
            const updates = [];
            if ("assigned" in ticket) {
              updates.push(
                this.deleteObject(
                  "helpdesk/inboxes/" + ticket.assigned.uid,
                  key
                )
              );
            }
            // Update ticket in mailbox
            ticket.assigned = {
              uid: uid,
              name: name
            };
            if (avatar) {
              ticket.assigned.avatar = avatar;
            }
            if ("mailbox" in ticket) {
              updates.push(
                this.updateObject(
                  "helpdesk/tickets/" + ticket.mailbox,
                  key,
                  ticket
                )
              );
            }
            Promise.all(updates)
              .then(() => {
                // Insert ticket into new inbox
                this.updateObject("helpdesk/inboxes/" + uid, key, ticket);
              })
              .catch(err => {
                console.error(
                  "Failed to assign ticket(s)",
                  this.selectedTickets.toString(),
                  err
                );
                window.toastr.error(
                  "An error occurred assigning the ticket(s): " + err
                );
              });
          }
        });
        this.selectedTickets = [];
      }
    },
    mergeTickets() {
      if (this.mergeKey && this.selectedTickets.length > 0) {
        let activity = {};
        let notes = {};
        let updates = {};
        this.selectedTickets.forEach(key => {
          if (key && key !== this.mergeKey) {
            // Get ticket
            let ticket = this.tickets[this.getIndex(this.tickets, key)];
            // Add activity, notes, and updates into combined
            if ("activity" in ticket) {
              activity = { ...activity, ...ticket.activity };
            }
            if ("notes" in ticket) {
              notes = { ...notes, ...ticket.notes };
            }
            if ("updates" in ticket) {
              updates = { ...updates, ...ticket.updates };
            }
            // Delete ticket from mailboxes
            const deletes = [];
            if ("sender" in ticket) {
              deletes.push(
                this.deleteObject("tickets/" + ticket.sender.uid, key)
              );
            }
            if ("assigned" in ticket) {
              deletes.push(
                this.deleteObject(
                  "helpdesk/inboxes/" + ticket.assigned.uid,
                  key
                )
              );
            }
            if ("mailbox" in ticket) {
              deletes.push(
                this.deleteObject("helpdesk/tickets/" + ticket.mailbox, key)
              );
            }
            Promise.all(deletes).catch(err => {
              console.error(
                "Failed to assign ticket(s)",
                this.selectedTickets.toString(),
                err
              );
              window.toastr.error(
                "An error occurred assigning the ticket(s): " + err
              );
            });
          }
        });
        this.selectedTickets = [];
        // Merge activity, notes, and updates into remaining ticket
        let ticket = this.tickets[this.getIndex(this.tickets, this.mergeKey)];
        if ("activity" in ticket) {
          ticket.activity = { ...ticket.activity, ...activity };
        } else {
          ticket.activity = { ...activity };
        }
        if ("notes" in ticket) {
          ticket.notes = { ...ticket.notes, ...notes };
        } else {
          ticket.notes = { ...notes };
        }
        if ("updates" in ticket) {
          ticket.updates = { ...ticket.updates, ...updates };
        } else {
          ticket.updates = { ...updates };
        }
        const promises = [];
        if ("sender" in ticket) {
          promises.push(
            this.updateObject(
              "tickets/" + ticket.sender.uid,
              this.mergeKey,
              ticket
            )
          );
        }
        if ("assigned" in ticket) {
          promises.push(
            this.updateObject(
              "helpdesk/inboxes/" + ticket.assigned.uid,
              this.mergeKey,
              ticket
            )
          );
        }
        if ("mailbox" in ticket) {
          promises.push(
            this.updateObject(
              "helpdesk/tickets/" + ticket.mailbox,
              this.mergeKey,
              ticket
            )
          );
        }
        Promise.all(promises).catch(err => {
          console.error(
            "Failed to merge tickets",
            this.selectedTickets.toString(),
            err
          );
          window.toastr.error(
            "An error occurred merging the ticket(s): " + err
          );
        });
      }
    },
    viewTicket(ticket) {
      console.log(ticket);
      let index = this.tabExists(ticket);
      if (index === null) {
        index = this.createTab(ticket);
      }
      this.showTab(index);
    },
    tabExists(ticket) {
      let tabIndex = null;
      this.tabs.forEach((tab, index) => {
        if (tab.ticket.id === ticket.id) {
          tabIndex = index + 1;
        }
      });
      return tabIndex;
    },
    createTab(ticket) {
      let tab = new Object();
      ticket.message =
        ticket.subject && String(ticket.subject).length > 40
          ? ticket.subject + "..."
          : ticket.subject;
      tab.ticket = ticket;
      tab.key = ticket[".key"];
      tab.name = "#" + ticket.id + " - " + ticket.message;
      tab.sender = ticket.sender;
      tab.sender.avatar = this.avatar;
      this.tabs.push(tab);
      const index = this.openTabs.length - 1;
      // Get user
      const u = new Users();
      u.getUser(tab.sender.uid)
        .then(async resp => {
          let user = resp.data;
          console.log(user);
          let signupDate = DateTime.fromHTTP(user.auth.metadata.creationTime);
          let sender = {
            uid: user.auth.uid,
            name: user.auth.displayName,
            email: user.auth.email,
            signup_date: signupDate.toLocaleString(DateTime.DATE_FULL)
          };
          if ("customClaims" in user.auth) {
            if (user.auth.customClaims.admin) {
              sender.type = "Administrator";
            } else if (user.auth.customClaims.subscribed) {
              sender.type = "Premium Member";
            } else {
              sender.type = "Free Member";
            }
          } else {
            sender.type = "Free Member";
          }
          if (user.db.profile && "avatar" in user.db.profile) {
            const url = await this.getImageUrl(
              "avatars/" + user.db.profile.avatar
            );
            sender.avatar = url;
          } else {
            sender.avatar = this.avatar;
          }
          this.tabs[index - 1].sender = { ...sender };
          this.sender = sender;
        })
        .catch(error => {
          console.error("Unable to fetch user", tab.sender.uid, error);
        });
      return index;
    },
    showTab(index) {
      let tab = this.openTabs[index];
      this.activeTab = index;
      this.ticket = tab.ticket;
      this.sender = tab.sender;
      this.ticketKey = tab.key;
    },
    refreshTab() {
      let index = this.getIndex(this.tickets, this.ticketKey);
      this.tabs[this.activeTab - 1].ticket = { ...this.tickets[index] };
      this.ticket = { ...this.tickets[index] };
    },
    closeTab(index) {
      if (this.activeTab === index) {
        this.showTab(index - 1);
      }
      this.tabs.splice(index - 1, 1);
    },
    getLastUpdate(updates) {
      const updatesArray = Object.values(updates);
      return updatesArray[updatesArray.length - 1];
    },
    getLastUpdateDate(updates) {
      const update = this.getLastUpdate(updates);
      return update.date;
    },
    deriveHeader(update) {
      switch (update.type) {
        case "update":
          return "New reply from " + update.author.name;
        case "close-message":
          return "Ticket closed by " + update.author.name;
        default:
          return "New message from " + update.author.name;
      }
    },
    getUpdateAvatar(update) {
      if (
        (update.updateType === "update" && update.type === "update") ||
        update.updateType === "activity" ||
        update.updateType === "note"
      ) {
        if ("author" in update) {
          return this.support_avatars[update.author.uid];
        } else {
          return this.avatar;
        }
      } else {
        return this.sender.avatar;
      }
    },
    addEditorButtons() {
      console.log("Editor ready");
      document.documentElement.style.setProperty(
        "--ck-color-base-background",
        "#fff"
      );
    },
    setUpdateType(type) {
      if (type === "note") {
        document.querySelector("#ticket-new-update").classList.add("note");
        document.documentElement.style.setProperty(
          "--ck-color-base-background",
          "lightyellow"
        );
      } else {
        document.querySelector("#ticket-new-update").classList.remove("note");
        document.documentElement.style.setProperty(
          "--ck-color-base-background",
          "#fff"
        );
      }
    },
    toggleNote() {
      if (this.update.type === "note") {
        this.update.type = "update";
      } else {
        this.update.type = "note";
      }
      this.setUpdateType(this.update.type);
    },
    async postUpdate() {
      this.posting = true;
      let update = {
        text: this.update.text,
        type: this.update.type,
        author: {
          uid: this.userProfile.uid,
          name: this.userProfile.name
        },
        date: Date.now()
      };
      console.log(update);
      let updateKey;
      if (this.update.existing) {
        if (this.update.key === null) {
          return false;
        } else {
          updateKey = this.update.key;
        }
      } else {
        updateKey = await this.createObjectKey();
      }
      let updates = [];
      if (this.update.type === "note") {
        if ("assigned" in this.ticket) {
          updates.push(
            this.updateObject(
              "helpdesk/inboxes/" +
                this.ticket.assigned.uid +
                "/" +
                this.ticketKey +
                "/notes",
              updateKey,
              update
            )
          );
        }
        if ("mailbox" in this.ticket) {
          updates.push(
            this.updateObject(
              "helpdesk/tickets/" +
                this.ticket.mailbox +
                "/" +
                this.ticketKey +
                "/notes",
              updateKey,
              update
            )
          );
        }
      } else {
        if ("assigned" in this.ticket) {
          updates.push(
            this.updateObject(
              "helpdesk/inboxes/" +
                this.ticket.assigned.uid +
                "/" +
                this.ticketKey +
                "/updates",
              updateKey,
              update
            )
          );
        }
        if ("mailbox" in this.ticket) {
          updates.push(
            this.updateObject(
              "helpdesk/tickets/" +
                this.ticket.mailbox +
                "/" +
                this.ticketKey +
                "/updates",
              updateKey,
              update
            )
          );
        }
        if ("sender" in this.ticket && "uid" in this.ticket.sender) {
          updates.push(
            this.updateObject(
              "tickets/" +
                this.ticket.sender.uid +
                "/" +
                this.ticketKey +
                "/updates",
              updateKey,
              update
            )
          );
        }
      }
      try {
        await Promise.all(updates);
        console.log("Update", updateKey);
        this.resetUpdate();
      } catch (e) {
        console.log("Update Error", e);
        window.toastr.error("Unable to post update: " + e);
      }
      this.refreshTab();
      this.posting = false;
    },
    editUpdate(update) {
      console.log(update);
      this.update.text = update.text;
      this.update.type = update.updateType;
      this.setUpdateType(this.update.type);
      this.update.existing = true;
      this.update.key = update.key;
    },
    deleteUpdate(key, type) {
      let deletes = [];
      let path = type === "note" ? "/notes" : "/updates";
      if ("assigned" in this.ticket) {
        deletes.push(
          this.deleteObject(
            "helpdesk/inboxes/" +
              this.ticket.assigned.uid +
              "/" +
              this.ticketKey +
              path,
            key
          )
        );
      }
      if ("mailbox" in this.ticket) {
        deletes.push(
          this.deleteObject(
            "helpdesk/tickets/" +
              this.ticket.mailbox +
              "/" +
              this.ticketKey +
              path,
            key
          )
        );
      }
      if ("sender" in this.ticket && "uid" in this.ticket.sender) {
        deletes.push(
          this.deleteObject(
            "tickets/" + this.ticket.sender.uid + "/" + this.ticketKey + path,
            key
          )
        );
      }
      Promise.all(deletes)
        .then(() => {
          this.refreshTab();
        })
        .catch(err => {
          window.toastr.error("Unable to delete " + type + ": " + err);
        });
    },
    resetUpdate() {
      this.update = {
        text: "",
        type: "update",
        existing: false,
        key: null
      };
      this.setUpdateType("update");
    },
    insertReply(reply) {
      this.update.text += reply.text;
    },
    assignTicket(key, value) {
      console.log(key, value);
      // Get user to assign
      let user = this.support_users[
        this.getIndex(this.support_users, value, "uid")
      ];
      console.log(user);
      // Get ticket from key and add assigned user
      let ticket;
      if (this.ticketKey === key) {
        ticket = { ...this.ticket };
      } else {
        ticket = { ...this.tickets[this.getIndex(this.tickets, key)] };
      }
      ticket.assigned = {
        uid: user.uid,
        name: user.name,
        avatar: user.avatar
      };
      console.log(ticket);
      // Update ticket in shared inbox and sender inbox
      let updates = [];
      if ("mailbox" in ticket) {
        updates.push(
          this.updateObject("helpdesk/tickets/" + ticket.mailbox, key, ticket)
        );
      }
      if ("sender" in ticket && "uid" in ticket.sender) {
        updates.push(
          this.updateObject("tickets/" + ticket.sender.uid, key, ticket)
        );
      }
      // Add ticket to assigned support user inbox
      updates.push(
        this.updateObject(
          "helpdesk/inboxes/" + ticket.assigned.uid,
          key,
          ticket
        )
      );
      // Remove ticket from any other support user inboxes
      for (let su of this.support_users) {
        if (su.uid !== ticket.assigned.uid) {
          updates.push(this.deleteObject("helpdesk/inboxes/" + su.uid, key));
        }
      }
      Promise.all(updates)
        .then(() => {
          console.log(
            "Ticket " +
              key +
              " assigned to user " +
              user.name +
              " (" +
              user.uid +
              ")."
          );
        })
        .catch(err => {
          window.toastr.error(
            "An error occurred assigning user to ticket: " + err
          );
        });
    },
    async updateStatus(key, value) {
      console.log(key, value);
      // Get status to assign
      let status = this.statuses[this.getIndex(this.statuses, value, "status")];
      console.log(status);
      // Get ticket from key
      let ticket;
      if (this.ticketKey === key) {
        ticket = { ...this.ticket };
      } else {
        ticket = { ...this.tickets[this.getIndex(this.tickets, key)] };
      }
      console.log(ticket);
      // Get new key for activity
      let activityKey = await this.createObjectKey();
      let internalUpdate = {
        status: status.status,
        activity: {
          [activityKey]: {
            author: {
              uid: this.userProfile.uid,
              name: this.userProfile.name
            },
            date: Date.now(),
            event: "status changed to " + status.status
          },
          ...ticket.activity
        }
      };
      let customerUpdate = {
        status: status.customer
      };
      console.log(internalUpdate, customerUpdate);
      // Update ticket in shared inbox and sender inbox
      let updates = [];
      if ("mailbox" in ticket) {
        updates.push(
          this.updateObject(
            "helpdesk/tickets/" + ticket.mailbox,
            key,
            internalUpdate
          )
        );
      }
      if ("sender" in ticket && "uid" in ticket.sender) {
        updates.push(
          this.updateObject("tickets/" + ticket.sender.uid, key, customerUpdate)
        );
      }
      // Update ticket in assigned support user inbox
      if ("assigned" in ticket && "uid" in ticket.assigned) {
        updates.push(
          this.updateObject(
            "helpdesk/inboxes/" + ticket.assigned.uid,
            key,
            internalUpdate
          )
        );
      }
      Promise.all(updates)
        .then(() => {
          console.log(
            "Ticket " + key + " status updated to " + status.status + "."
          );
        })
        .catch(err => {
          window.toastr.error(
            "An error occurred updating ticket status: " + err
          );
        });
    },
    computeSLAs(ticket, sender) {
      let slas = [];
      this.slas.forEach(sla => {
        if (sender.type === sla.role) {
          if (ticket.status === "Completed" || ticket.status === "Cancelled") {
            sla.completed = true;
            sla.time_remaining = 0;
            sla.percent = 100;
            sla.style = "success";
          } else {
            const now = DateTime.now();
            switch (sla.type) {
              case "first-response": {
                let updateCount;
                if ("updates" in ticket) {
                  updateCount = Object.values(ticket.updates).filter(update => {
                    return update.type === "update";
                  }).length;
                } else {
                  updateCount = 0;
                }
                if (updateCount > 0) {
                  sla.completed = true;
                  sla.time_remaining = 0;
                } else {
                  sla.completed = false;
                  let created = DateTime.fromMillis(ticket.date);
                  let elapsed = now.diff(created, "minutes");
                  sla.time_remaining = sla.duration - elapsed.as("minutes");
                }
              }
              // eslint-disable-next-line no-fallthrough
              case "follow-up": {
                if ("updates" in ticket) {
                  let replies = Object.values(ticket.updates).filter(update => {
                    return update.type === "update";
                  });
                  if (replies.length > 0) {
                    let lastReply = replies[replies.length - 1];
                    let updated = DateTime.fromMillis(lastReply.date);
                    let elapsed = now.diff(updated, "minutes");
                    sla.time_remaining = sla.duration - elapsed.as("minutes");
                  } else {
                    let created = DateTime.fromMillis(ticket.date);
                    let elapsed = now.diff(created, "minutes");
                    sla.time_remaining = sla.duration - elapsed.as("minutes");
                  }
                } else {
                  let created = DateTime.fromMillis(ticket.date);
                  let elapsed = now.diff(created, "minutes");
                  sla.time_remaining = sla.duration - elapsed.as("minutes");
                }
                sla.completed = false;
              }
              // eslint-disable-next-line no-fallthrough
              case "completion": {
                let created = DateTime.fromMillis(ticket.date);
                let elapsed = now.diff(created, "minutes");
                sla.time_remaining = sla.duration - elapsed.as("minutes");
              }
            }
            if (sla.time_remaining === 0) {
              sla.percent = 100;
            } else {
              sla.percent =
                100 - Math.round((sla.time_remaining / sla.duration) * 100);
            }
            if (!sla.completed && sla.percent >= 75) {
              sla.style = "danger";
            } else if (!sla.completed && sla.percent >= 50) {
              sla.style = "warning";
            } else {
              sla.style = "success";
            }
          }
          slas.push(sla);
        }
      });
      return slas;
    },
    getNextDueSLA(ticket, sender) {
      sender.type = !sender.type ? "Free Member" : sender.type;
      let slas = this.computeSLAs(ticket, sender);
      let due = slas.filter(sla => {
        return sla.completed === false;
      });
      console.log(slas, due);
      if (due.length > 0) {
        let ordered = slas.sort((a, b) => {
          if (a.time_remaining < b.time_remaining) {
            return 1;
          } else if (b.time_remaining < a.time_remaining) {
            return -1;
          } else {
            return 0;
          }
        });
        return ordered[0];
      } else {
        return {};
      }
    }
  },
  created() {
    this.getSupportUsers();
  },
  mounted() {
    this.widthOfList();
    this.getHiddenLeft();
    this.getHiddenRight();
    this.reAdjust();
  }
};
</script>

<style>
main {
  height: calc(100% - 70px);
}

#content {
  height: 100%;
}

.ck.ck-editor__editable_inline {
  border-top: 1px solid transparent;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.full-height {
  height: 100%;
}

.help-desk-card {
  height: 100%;
}

.tab-content-row {
  height: calc(100% - 42px);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: #fff;
}

.tab-bar-wrapper {
  float: left;
  width: 100%;
}

.scroller {
  text-align: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

.tab-bar-inner-wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  height: 42px;
}

.help-desk-nav-tab-bar {
  border-bottom: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  min-width: 3500px;
  margin-top: 0px;
}

.help-desk-nav-tab-bar > .nav-item {
  margin-bottom: -2px;
}

.help-desk-nav-tabs {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  cursor: pointer;
}

.help-desk-nav-tabs:hover {
  background-color: rgba(0, 0, 0, 0.125) !important;
}

.help-desk-tab-close {
  line-height: 1;
  padding: 2px;
  margin-top: -5px;
  background-color: transparent;
  color: #6c757d;
  border: 0;
}

.help-desk-tab-close:hover {
  color: #dc3545;
}

.search-box-col {
  max-width: 210px;
}

.search-box-wrapper {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.search-box {
  line-height: 0.8;
  height: 2rem;
}

.sla-progress {
  height: 10px;
  width: 60px;
  margin-top: 10px;
}

.ticket-card {
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 15px;
  padding: 0.5rem;
}

.sender {
  margin-right: 50px;
}

.responder {
  margin-left: 50px;
  background-color: #e2f5ff;
}

.responder-internal {
  margin-left: 50px;
  background-color: lightyellow;
}

.activity {
  margin-right: 50px;
  background-color: #2a2a2e;
}

#ticket-new-update {
  overflow-x: hidden;
  overflow-y: auto;
}

.note {
  background-color: lightyellow;
}

.comment-box {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.comment-box textarea,
.comment-box .btn-primary {
  outline: 0 !important;
}
.comment-box textarea {
  width: 100%;
  min-height: 100px;
  padding: 20px;
  border: none;
  resize: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.comment-box textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 15px;
}
.comment-box textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 15px;
}
.comment-box textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 15px;
}
.comment-box textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #bdbdbd;
  font-weight: 300;
  font-size: 15px;
}
.comment-box .card-body {
  padding: 0;
}
.comment-box .card-footer {
  background-color: #fff;
}

.sender-details {
  text-align: center;
  max-width: 250px;
  margin-top: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.sender-details > ul {
  padding-left: 0;
}

.chip-lg {
  display: inline-block;
  padding: 0;
  height: 60px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 30px;
}

.chip-lg img {
  float: left;
  margin: 0;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.chip-xl {
  display: inline-block;
  padding: 0;
  height: 90px;
  font-size: 14px;
  line-height: 90px;
  border-radius: 45px;
}

.chip-xl img {
  float: left;
  margin: 0;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.sla-text {
  margin-top: -25px;
}

.btn-borderless {
  padding: 0.125rem 0.25rem;
  border: 0;
}

.editor-buttons {
  top: 4px;
  z-index: 99;
  text-align: right;
  margin-right: 0.25rem;
  height: 0;
}
</style>
