<template>
  <div
    class="container-fluid p-2"
    style="overflow-x: hidden; overflow-y: auto;"
  >
    <div class="row">
      <div class="col">
        <div class="card-deck">
          <div class="card shadow">
            <div class="card-body">
              <h1 class="card-title">
                {{ counts.total }}
              </h1>
            </div>
            <div class="card-footer bg-dark text-light">
              Total Support Requests
            </div>
          </div>
          <div class="card shadow">
            <div class="card-body">
              <h1 class="card-title">
                {{ counts.in_progress }}
              </h1>
            </div>
            <div class="card-footer bg-dark text-light">
              In Progress
            </div>
          </div>
          <div class="card shadow">
            <div class="card-body">
              <h1 class="card-title">
                {{ counts.unassigned }}
              </h1>
            </div>
            <div class="card-footer bg-dark text-light">
              Unassigned
            </div>
          </div>
          <div class="card shadow">
            <div class="card-body">
              <h1 class="card-title text-danger">
                {{ counts.overdue }}
              </h1>
            </div>
            <div class="card-footer bg-dark text-light">
              Overdue
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card shadow" style="margin-top: 30px;">
          <div class="card-body">
            <div id="opened-graph-container">
              <canvas
                id="opened-graph"
                style="width: 100%; max-height: 300px;"
              ></canvas>
            </div>
          </div>
          <div class="card-footer bg-dark text-light">
            Opened Tickets
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card shadow" style="margin-top: 30px;">
          <div class="card-body">
            <div id="completed-graph-container">
              <canvas
                id="completed-graph"
                style="width: 100%; max-height: 300px;"
              ></canvas>
            </div>
          </div>
          <div class="card-footer bg-dark text-light">
            Completed Tickets
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";

export default {
  name: "Overview",
  mixins: [FirebaseMixin, UtilsMixin],
  data() {
    return {
      counts: {
        total: 2,
        in_progress: 0,
        unassigned: 2,
        overdue: 2
      }
    };
  },
  watch: {
    // userUid: {
    //   immediate: true,
    //   handler(uid) {
    //     if (uid) {
    //       this.bindObject(this.ref + "/" + uid, null, "messages");
    //     }
    //   }
    // }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    assignedCount() {
      return 5;
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"])
  }
};
</script>

<style scoped></style>
