<template>
  <mailbox :name="mailbox.name" :tickets="tickets"></mailbox>
</template>

<script>
import { mapState, mapMutations } from "vuex";
//import firebase from "firebase";
import FirebaseMixin from "@/mixins/Firebase";
import UtilsMixin from "@/mixins/Utils";
import Mailbox from "@/components/Mailbox";

export default {
  name: "Inbox",
  mixins: [FirebaseMixin, UtilsMixin],
  components: {
    mailbox: Mailbox
  },
  data() {
    return {
      mailbox: {
        name: "Inbox"
      },
      tickets: []
    };
  },
  watch: {
    userUid: {
      immediate: true,
      handler(uid) {
        if (uid) {
          this.bindObject("helpdesk/inboxes/" + uid, null, "tickets");
        }
      }
    }
  },
  computed: {
    ...mapState(["isAuthenticated", "userProfile"]),
    userUid() {
      return this.userProfile.uid;
    }
  },
  methods: {
    ...mapMutations(["setUser", "setProfile"])
  }
};
</script>

<style scoped></style>
